<template>
  <div class='px-2 lg:px-0 pt-4 lg:pt-12'>
    <session-details-full
      v-for='(sessionDetail, index) in sessionDetailsListAllCategoriesWithExceptions'
      :key='`sessionDetail-${sessionDetail.id}`'
      :session='sessionDetail'
      :repeatedComponent='true'
      :session-index='index'
      class='mb-12 lg:mb-16 pb-4 p-0 lg:p-6 border-b lg:border border-gray-100 lg:rounded-md lg:shadow-md' />
    <div v-if='emptySessionDetailsList'
      class='px-16 py-24 text-center bg-gray-100 rounded-md max-w-4xl'>
      No sessions found. Please update your filter settings.
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SessionDetailsFull from '@/components/icola2023/SessionDetailsFull.vue'
import dayjs from 'dayjs'

export default {
  name: 'ProgramDetailsForSingleCategory',
  components: {
    SessionDetailsFull,
  },
  props: [
    'needToBeLoggedIn',
  ],
  computed: {
    ...mapState('sessions', [
      'sessionDetailsList',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    sessionDetailsListAllCategoriesWithExceptions () {
      return this.sessionDetailsList.filter(session => {
        return session.category !== 'break' && 
               session.sessionType !== 'break' &&
               !session.configurations.hideSessionInProgramDetails
      }).sort((a, b) => {
        // sort by time, then room
        if (a.scheduledStartTime < b.scheduledStartTime) {
          return -1
        } else if (a.scheduledStartTime > b.scheduledStartTime) {
          return 1
        } else {
          if (a.locations[0] < b.locations[0]) {
            return -1
          } else if (a.locations[0] > b.locations[0]) {
            return 1
          } else {
            return 0
          }
        }
      })
    },
    emptySessionDetailsList () {
      return this.sessionDetailsListAllCategoriesWithExceptions.length === 0
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSessionDetailsList',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    sessionDateString (dateString) {
      if (this.isMobile) {
        return dateString = dayjs(dateString).format('MMM DD (ddd)')
      } else {
        return dateString = dayjs(dateString).format('MMMM DD (ddd)')
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.needToBeLoggedIn) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'ProgramDetails'} })
      }
    })
  },
  mounted () {
    this.getSessionDetailsList({categories: this.$route.query.categories})
  }
}
</script>
